import moment from 'moment';
import CryptoJs from 'crypto-js';

const Sleep = function (time: any) {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const PageSizeList = [10, 50, 100, 200];

//根据出生日期计算年龄
const GetAge = function (strBirthday: string) {
  let returnAge = -1;
  const strBirthdayArr = strBirthday.split('-');
  const birthYear: any = strBirthdayArr[0];
  const birthMonth: any = strBirthdayArr[1];
  const birthDay: any = strBirthdayArr[2];

  const fmtToday = moment(new Date()).format('YYYY-MM-DD');
  const dayArr = fmtToday.split('-');
  const nowYear: any = dayArr[0];
  const nowMonth: any = dayArr[1];
  const nowDay: any = dayArr[2];

  if (nowYear === birthYear) {
    returnAge = 0;//同年 则为0岁
  } else {
    const ageDiff = nowYear - birthYear; //年之差
    if (ageDiff > 0) {
      if (nowMonth === birthMonth) {
        const dayDiff = nowDay - birthDay;//日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        const monthDiff = nowMonth - birthMonth;//月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1;
    }
  }

  return returnAge;
};

/**
 * 加密
 */
const Encrypt = function (word: string) {
  const key = CryptoJs.enc.Utf8.parse("12345ABCDEF67890");
  const srcs = CryptoJs.enc.Utf8.parse(word);
  const encrypted = CryptoJs.AES.encrypt(srcs, key, {
    mode: CryptoJs.mode.ECB,
    padding: CryptoJs.pad.Pkcs7
  });
  return encrypted.toString();
};

/**
 * 解密
 */
const Decrypt = function (word: string) {
  const key = CryptoJs.enc.Utf8.parse("12345ABCDEF67890");
  const decrypt = CryptoJs.AES.decrypt(word, key, {
    mode: CryptoJs.mode.ECB,
    padding: CryptoJs.pad.Pkcs7
  });
  return CryptoJs.enc.Utf8.stringify(decrypt).toString();
}

export { Sleep, PageSizeList, GetAge, Encrypt, Decrypt };


import { Options, Vue } from "vue-class-component";
import { goodsSpecTypes } from "../../middlewares/enum";
import { Action } from "vuex-class";
import qs from "qs";
import UserCollectGood from "../../components/UserCollectGood.vue";
import { Sleep } from "../../middlewares/common";
import moment from "moment";
@Options({
  components: {
    UserCollectGood
  }
})
export default class UserCollectGoods extends Vue {
  @Action("setUserOpenId") private setUserOpenId: any;
  @Action("setCollectGoods") private setCollectGoods: any;
  private showSpecPicker = false;
  private specPickerIdx = 0;
  private preCodeLoading = false;
  // 部门列表数据
  private deptList = [];
  // 采集人列表数据
  private deptUserList = [];
  private specTypes = goodsSpecTypes;
  private showUserPicker = false;
  private userPickerIdx = 0;
  private showDeptPicker = false;
  private deptPickerIdx = 0;

  private colForm: any = {
    siteName: "",
    collectNo: "",
    specType: {
      name: "",
      code: ""
    },
    user: {
      id: "",
      realname: ""
    }, //采集人
    dept: {
      id: "",
      name: ""
    }, //采集部门
    orgName: "", //采集机构
    goodsList: []
  };

  public created() {
    this.findUsersByOrgId();

    this.deptList = this.$store.state.userInfo.depts;
    //判断是否有暂存本地的采集信息，如果有则加载本地数据，否则初始化基本信息
    if (this.$store.state.goodsInfo) {
      this.colForm = JSON.parse(JSON.stringify(this.$store.state.goodsInfo));
    } else {
      this.colForm.user.id = this.$store.state.userInfo.id;
      this.colForm.user.realname = this.$store.state.userInfo.realname;
      this.colForm.dept.id = this.$store.state.userInfo.curDept.deptId;
      this.colForm.dept.name = this.$store.state.userInfo.curDept.deptName;
      this.colForm.orgName = this.$store.state.userInfo.orgName;
      this.colForm.specType.code = this.specTypes[0].code;
      this.colForm.specType.name = this.specTypes[0].name;
    }
    const temp = this.deptList.findIndex(
      (p: any) => p.id === this.colForm.dept.id
    );
    if (temp >= 0) {
      this.deptPickerIdx = temp;
    }
  }

  // 获取上报人列表
  private findUsersByOrgId() {
    const orgId = this.$store.state.userInfo.orgId;
    const url = "/api/user/findUsersByOrgId";
    const param = { params: { orgId } };
    this.axios
      .get(url, param)
      .then((res: any) => {
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          this.deptUserList = res.data.userList;
          const temp = this.deptUserList.findIndex(
            (p: any) => p.id === this.colForm.user.id
          );
          if (temp >= 0) {
            this.userPickerIdx = temp;
          }
        }
      })
      .catch(err => {
        this.$toast.fail(err);
      });
  }

  //选择采样方式
  private onConfirmSpecPicker(value: any) {
    this.colForm.specType.code = value.code;
    this.colForm.specType.name = value.name;
    this.showSpecPicker = false;
  }
  //选择采集人用户
  private onConfirmUserPicker(value: any) {
    this.colForm.user.id = value.id;
    this.colForm.user.realname = value.realname;
    this.showUserPicker = false;
  }
  //选择所属部门
  private onConfirmDeptPicker(value: any) {
    this.colForm.dept.id = value.id;
    this.colForm.dept.name = value.name;
    this.showDeptPicker = false;
  }
  //人工采集
  private userCollect() {
    const temp: any = this.$refs.cmptGood;
    temp.openGoodInfo();
  }
  //添加物品回调
  private getAddedGood(good: any) {
    if (good.idx === null) {
      if (this.colForm.goodsList.length === 0) {
        good.idx = 0;
      } else {
        good.idx =
          this.colForm.goodsList[this.colForm.goodsList.length - 1].idx + 1;
      }
      this.colForm.goodsList.push(good);
    } else {
      const temp = this.colForm.goodsList.findIndex(
        (p: any) => p.idx === good.idx
      );
      if (temp >= 0) {
        this.colForm.goodsList[temp] = good;
      }
    }
    //本地保存临时信息
    this.setCollectGoods(this.colForm);
  }
  //删除采样物品信息
  private deleteGood(good: any, idx: number) {
    this.$dialog
      .confirm({
        title: "确认",
        message: "确认要删除采样物品信息？"
      })
      .then(() => {
        this.colForm.goodsList.splice(idx, 1);
        //本地保存临时信息
        this.setCollectGoods(this.colForm);
      })
      .catch(() => {
        console.log("cancel delete");
      });
  }
  //修改采样物品信息
  private editGood(good: any, idx: number) {
    //打开居民弹框
    const temp: any = this.$refs.cmptGood;
    temp.editGood(good);
  }
  //提交
  private async submitCollectInfo() {
    if (!this.colForm.siteName || this.colForm.siteName === "") {
      this.$toast.fail("无法提交，请先添加采样地点信息！");
      return;
    }
    if (this.colForm.goodsList.length === 0) {
      this.$toast.fail("无法提交，请先添加采样物品信息！");
      return;
    }
    if (!this.colForm.collectNo || this.colForm.collectNo === "") {
      this.$toast.fail("无法提交，请先绑定预制条码！");
      return;
    }

    const toast = this.$toast.loading({
      duration: 0,
      forbidClick: true,
      message: "提交样本中，请稍后..."
    });

    const obj = {
      siteName: this.colForm.siteName,
      specTypeCode: this.colForm.specType.code,
      specType: this.colForm.specType.name,
      userId: this.colForm.user.id,
      deptId: this.colForm.dept.id,
      orgId: this.$store.state.userInfo.orgId,
      orgCode: this.$store.state.userInfo.orgCode,
      collectTime: moment(new Date()).format("YYYY-MM-DD,HH:mm:ss"),
      collectNo: this.colForm.collectNo,
      isPreCode: 1,
      goodsList: this.colForm.goodsList.map((p: any) => {
        return {
          //   envType: p.envType,
          //   otherType: p.otherType,
          //   specObj: p.specObj,
          specName: p.specName
        };
      })
    };
    const url = "/api/collect/addCovidCollectEnv";
    this.axios
      .post(url, qs.stringify(obj, { arrayFormat: "indices" }))
      .then(async (res: any) => {
        if (res.status.code === 1) {
          this.$toast.success("提交成功");
          this.colForm.collectNo = "";
          this.colForm.goodsList = [];
          //清空本次采集的缓存
          this.setCollectGoods(this.colForm);
        } else {
          this.$toast.fail("提交失败：" + res.status.msg);
        }
        await Sleep(300);
        toast.clear();
      })
      .catch(err => {
        toast.clear();
      });
  }
}


import { Options, Vue } from "vue-class-component";
import qs from "qs";
import moment from "moment";
import { Sleep } from "../middlewares/common";
import { envTypes } from "../middlewares/enum";

export default class UserCollectGood extends Vue {
  private show = false;
  private goodForm = {
    idx: null, //序号：编辑时使用
    // envType: [], //环境类型
    // otherType: "", //其他类型
    // specObj: "", //采集对象
    specName: "" //样本名称
  };

  private openGoodInfo() {
    this.show = true;
  }

  // 编辑物品信息
  private editGood(good: any) {
    this.show = true;
    this.goodForm = good;
  }

  //提交
  private submitForm() {
    if (!this.goodForm.specName || this.goodForm.specName.length === 0) {
      this.$toast.fail("请输入物品名称");
      return;
    }
    this.$emit("getGood", this.goodForm);
    this.cancelForm();
  }

  private cancelForm() {
    this.goodForm = {
      idx: null, //序号：编辑时使用
      //   envType: [], //环境类型
      //   otherType: "", //其他类型
      //   specObj: "", //采集对象
      specName: "" //样本名称
    };
    this.show = false;
  }
}
